.mySwiper {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-div-background-light-2);
}

.mySwiperSlide {
  background-position: center;
  background-size: cover;
  position: relative;

  max-height: 600px;
  margin: auto;
}

.img_desktop {
  display: block;
  width: 100%;
}

.img_mobile{
  display: block;
  width: 100%;
  height: 300px;
}



.overlayTextRight {
  position: absolute;
  top: 0;
  right: 5%;
  width: 45%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 5% 2% 5%;
  gap: 5%;
  text-align: center;
  background-color: var(--theme-div-background-light-2);
  opacity: 0.85;
  color: black;
}

.overlayTextLeft {
  position: absolute;
  top: 0;
  left: 5%;
  width: 45%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 5% 2% 5%;
  gap: 5%;
  text-align: center;
  background-color: var(--theme-div-background-light-2);
  opacity: 0.85;
  color: black;
}

.title{
  font-size: 2rem;
  font-weight: bold;
  color: var(--main-theme-color);
  -webkit-text-stroke: 0.7px black; /* Adds a 1px black outline */
}

.title_mobile{
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--main-theme-color);
  -webkit-text-stroke: 0.7px black; /* Adds a 1px black outline */
}


.text{
  /*font-style: oblique;*/
  font-size: 1.2rem;
  font-weight: bold;
}

.text_mobile{
  /*font-style: oblique;*/
  font-size: 0.8rem;
  font-weight: bold;
}
